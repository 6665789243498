import React, {useEffect, useState} from 'react'
import SubHeader from '../../Сomponents/moleculas/SubHeader/SubHeader';
import styles from "./styles.module.scss";
import News from "../../Сomponents/organisms/News/News";
import NewsCard from "../../Сomponents/atoms/NewsCard";
import NavigateBtn from "../../Сomponents/atoms/NavigateBtn";
import {useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getProjectsAll} from "../../redux/reducers/projectsReducer";
import {Link} from "react-router-dom";
import {Button} from "@mui/material";
import {useTranslation} from "react-i18next";
import Skeleton from "@mui/material/Skeleton";

const Projects = () => {

    const location = useLocation();

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getProjectsAll(localStorage.language ? localStorage.language : 1, localStorage.projectsCategory ? localStorage.projectsCategory : 1));
        if (location.pathname.includes('education')) {
            localStorage.setItem("projectsCategory", '1')
        }
    }, [location.pathname, localStorage.projectsCategory, localStorage.language]);

    const {projects} = useSelector(({projects}) => projects);
    const [data, setData] = useState(null);
    const [recomendData, setRecomendData] = useState(null);
    useEffect(() => {
        if (projects) {
            setData(projects.data);
            setRecomendData(projects.data);
        }
    }, [projects]);

    const [slicer, setSlicer] = useState(4);

    const {t} = useTranslation();

    return (
        <div className={styles.wrapper}>
            <div className="wrapper">
                <SubHeader/>
                <div className={styles.content_wrapper}>
                    <h1 className={styles.title}>{t('projectsPageTitle')}</h1>
                    {
                        data && data.length > 0 ?
                            <News
                                id={data.filter(item => item.typeDto.id === +localStorage.getItem('projectsCategory'))[0]?.id}
                                title={data.filter(item => item.typeDto.id === +localStorage.getItem('projectsCategory'))[0]?.head}
                                body={data.filter(item => item.typeDto.id === +localStorage.getItem('projectsCategory'))[0]?.description}
                                image={data.filter(item => item.typeDto.id === +localStorage.getItem('projectsCategory'))[0]?.imageDtos[0].url}/>
                            : <Skeleton variant="rectangular" width={"100%"} height={200} />
                    }
                    <div className={styles.recomendate}>
                        {recomendData && recomendData.length > 0 ? recomendData.slice(0, slicer).map((item, idx) => (
                            <div key={idx} className={styles.box}>
                                <Link to={`${item.id}`}>
                                    <NewsCard id={item?.id} title={item?.head}
                                              image={item?.imageDtos[0]?.url}
                                              subtitle={item?.description}/>
                                </Link>
                            </div>
                        )) : <Skeleton variant="rectangular" width={"100%"} height={200} />
                        }
                    </div>
                    <div className={styles.btnBox}>
                        <Button onClick={() => setSlicer(slicer + 4)} className="gallery__btn"
                                variant='text'>{t('allProjectsText')}<i
                            className="ri-arrow-right-s-line"/></Button>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Projects