import './App.css';
import {Header, Footer} from './Сomponents/moleculas';
import {Routes, Route} from "react-router-dom"
import {MainPage, Login, ContactPage, Gallery, PageNotFound, NewsPage, Projects, Admin} from './Pages';
import "@fancyapps/ui/dist/fancybox.css";
import React, {useEffect, useState} from "react";
import 'remixicon/fonts/remixicon.css';
import ProjectItem from "./Pages/ProjectItem";
import {Helmet} from "react-helmet";
import CreateProject from './Сomponents/organisms/CreateProject/CreateProject';
import UserApp from './Apps/UserApp';
import AdminApp from './Apps/AdminApp/AdminApp';
import Main from './Apps/Main';
import {getAllNews} from "./Store/Thunks/newsThunk"
import {useDispatch} from 'react-redux';
import PrivateRoute from "./PrivateRoute/PrivateRoute";

function App({store}) {
    const dispatch = useDispatch()
    // const location = useLocation();
    // useEffect(() => {
    //     document.documentElement.style.scrollBehavior = "unset";
    //     window.scrollTo(0, 0);
    //     document.documentElement.style.scrollBehavior = "smooth";
    // }, [location.pathname]);
    // const state = store.getState();
    // const token = localStorage.getItem("token")
    // const [auth,setAuth] = useState(true)
    // useEffect(()=>{
    //     if(token == null){
    //         setAuth(false)
    //     }
    // },[])
    const token = localStorage.getItem("token");
    const [auth, setAuth] = useState(true);

    useEffect(() => {
        if (!token) {
            setAuth(false)
        } else {
            setAuth(true)
        }
        if (localStorage.language !== '1' && localStorage.language !== '2' && localStorage.language !== '3') {
            localStorage.removeItem('language')
        }
    }, [localStorage.getItem("token")]);

    return (
        <div className="App">
            <Routes>
                <Route path="/" element={<Main/>}>
                    <Route index element={<UserApp/>}/>
                    <Route path="*" element={<UserApp/>}/>
                    <Route path="/admin/*" element={
                        <PrivateRoute auth={auth}>
                            <AdminApp/>
                        </PrivateRoute>
                    }/>
                </Route>
            </Routes>
        </div>
    );
}

export default App;
