import {combineReducers} from "redux"
import {loginReducer} from "./loginReducer"
import news from "../../redux/reducers/newsReducer";
import projects from "../../redux/reducers/projectsReducer";
import loader from "../../redux/reducers/loaderReducer";
import images from "../../redux/reducers/imagesReducer";
import subscribe from "../../redux/reducers/subscribeReducer";


export const rootReducer = combineReducers({
    auth: loginReducer,
    news,
    projects,
    loader,
    images,
    subscribe
});
