import React from 'react'
import styles from "./styles.module.scss"
import {useNavigate} from "react-router-dom";

const LastNews = ({date, text, id}) => {
    const navigate = useNavigate();

    const firstLatter = text.slice(0, 1).toUpperCase();
    const otherLetters = text.slice(1).toLowerCase();

    return (
        <div className={styles.wrapper}>
            <p onClick={() => navigate(`${id}`)} className={styles.body}>
                {firstLatter + otherLetters}
            </p>
            <h5 className={styles.date}>
                {date.slice(0, 10)}
            </h5>
        </div>
    )
};

export default LastNews