import React, {useEffect, useState} from 'react';
import styles from './ourProjects.module.scss';
import NavigateBtn from "../../Сomponents/atoms/NavigateBtn";
import SwiperCards from "../../Сomponents/atoms/SwiperCards";
import CategoriesList from "../../Сomponents/atoms/CategoriesList";
import {useDispatch, useSelector} from "react-redux";
import {getProjectsAll} from "../../redux/reducers/projectsReducer";
import {useTranslation} from "react-i18next";

const OurProjects = () => {
    const swiperBreakpoints = {
        1400: {
            slidesPerView: 3,
            spaceBetween: 30,
        },
        1300: {
            slidesPerView: 2,
            spaceBetween: 80,
        },
        921: {
            slidesPerView: 2,
            spaceBetween: 20,
        },
        850: {
            slidesPerView: 3,
            spaceBetween: 20,
        },
        650: {
            slidesPerView: 2,
            spaceBetween: 20,
        },
        577: {
            slidesPerView: 1,
        }
    };
    const {t} = useTranslation();

    const [category, setCategory] = useState(7);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getProjectsAll(localStorage.language ? localStorage.language : 1, category))
    }, [category, localStorage.language]);

    const {projects} = useSelector(({projects}) => projects);
    const [data, setData] = useState(null);
    useEffect(() => {
        if (projects) {
            setData(projects.data)
        }
    }, [projects]);

    return (
        <div className={styles.wrapper}>
            <div className="wrapper mainSwiper">
                <h2 className={styles.title}>{t('projectsTitle')}</h2>
                <NavigateBtn text={t('allProjectsText')} address={'projects/education'}/>
                <div className={styles.row}>
                    <CategoriesList setCategory={setCategory} category={category}/>
                    <div className={styles.box}>
                        <SwiperCards height={'30vw'} page={true} category={category} data={data ? data.reverse() : ''}
                                     padding={styles.small} margin={30}
                                     breakpoints={swiperBreakpoints}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OurProjects;