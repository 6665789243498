import React from "react";
import styles from "./styles.module.scss";
import {MainImage} from "../../Assets/images";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import i18n from '../../i18n';


const IntroSection = () => {

    const {t} = useTranslation();

    const navigate = useNavigate();
    return (
        <div className={styles.wrapper}>
            <div className={styles.firstPart}>
                <h1 className={styles.MainTitle}>
                    {t('introSectionTitle')}
                </h1>
                <p className={styles.subtext}>
                    {t('introSectionSubtitle')}
                </p>
                <button onClick={() => navigate('/appeal')}
                        className={styles.button}>
                    {t('applyButton')}
                </button>
            </div>
            <div className={styles.secondPart}>
                <img src={MainImage} alt="MainImage"/>
            </div>
        </div>
    );
};

export default IntroSection;
