import React, {useState} from 'react'
import styles from "./styles.module.scss"
import News from '../../Сomponents/organisms/News/News';
import {LastNews} from '../../Сomponents/moleculas';
import {NewsCard} from '../../Сomponents/atoms';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getNewsAll} from "../../redux/reducers/newsReducer";
import {Button} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Skeleton from "@mui/material/Skeleton";

const NewsPage = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getNewsAll(localStorage.language ? localStorage.language : 1))
    }, [localStorage.language]);

    const {news} = useSelector(({news}) => news);
    const [data, setData] = useState(null);
    const [recomendData, setRecomendData] = useState(null);
    useEffect(() => {
        if (news) {
            setData(news.data);
            setRecomendData(news.data)
        }
    }, [news]);

    const [slicer, setSlicer] = useState(7);


    const navigate = useNavigate();

    const {t} = useTranslation();

    return (
        <div className={styles.wrapper}>
            <div className="wrapper">
                <h1 className={styles.title}>{t('newsPageTitle')}</h1>
                <div className={styles.content_wrapper}>
                    {data && data.length > 0
                        ? <News id={data[0].id} title={data[0].head}
                                body={data[0].description}
                                date={data[0].date}
                                image={data[0].imageDtos[0].url}/>
                        : <Skeleton variant="rectangular" width={"100%"} height={200}/>
                    }
                </div>
                <div className={styles.lastNews}>
                    <h2 className={styles.subtitle}>{t('newsPageLastNews')}</h2>
                    <div className={styles.subcontent}>
                        <div className={styles.pagination}>
                            {data && data.length > 0
                                ? data.slice(0, slicer).map((item, idx) => (
                                    <LastNews key={idx} id={item.id} date={item.date}
                                              text={item.head}/>
                                ))
                                : <Skeleton variant="rectangular" width={"100%"} height={200}/>}
                            <div className={styles.btnBox}>
                                <Button onClick={() => setSlicer(slicer + 7)} className="gallery__btn"
                                        variant='text'>{t('allNewsText')}<i
                                    className="ri-arrow-right-s-line"/></Button>
                            </div>
                        </div>
                        <div className={styles.recomendation}>
                            {recomendData && recomendData.length > 0
                                ? recomendData.slice(1, 4).map((item, idx) => (
                                    <div key={idx} className={styles.box}>
                                        <Link to={`${item.id}`}>
                                            <NewsCard id={item.id} image={item.imageDtos} title={item.head}
                                                      subtitle={item.description} cardDate={item.date}/>
                                        </Link>
                                    </div>
                                ))
                                : <Skeleton variant="rectangular" width={"100%"} height={200}/>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default NewsPage