import React from 'react'
import styles from "./styles.module.scss"
import {useNavigate} from "react-router-dom";

const News = ({title, body, image, date, id}) => {
    const navigate = useNavigate();
    return (
        <div className={styles.wrapper}>
            <div className={styles.firstBlock}>
                <img onClick={() => navigate(`${id ? id : ''}`)} src={image ? image : ''} alt=""/>
            </div>
            <div className={styles.secondBlock}>
                {
                    date !== undefined ?
                        <h4 className={styles.data}>
                            {new Intl.DateTimeFormat('ru-Ru', {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                            }).format(new Date(date))}
                        </h4>
                        : ''
                }
                <h2 onClick={() => navigate(`${id}`)} className={styles.title}>
                    {title ? title.substr(0, 100) : ''}{title ? title.length > 100 ? '...' : '' : ''}
                </h2>
                {body ? body.slice(0, 300).split('\n').map((item, idx) => (
                        item.includes('   ')
                            ? (idx === body.slice(0, 300).split('\n').length - 1
                            ? <p style={{textIndent: "4%"}} className={styles.body}>{item}...</p>
                            : <p style={{textIndent: "4%"}} className={styles.body}>{item}</p>)
                            : (idx === body.slice(0, 300).split('\n').length - 1
                            ? <p className={styles.body}>{item}...</p>
                            : <p className={styles.body}>{item}</p>)
                    ))
                    : ''}
            </div>
        </div>
    )
}

export default News