import React, {useEffect, useState} from 'react'
import styles from "./styles.module.scss"
import {Link, useLocation} from 'react-router-dom'
import AdminBoard from '../../../../Сomponents/moleculas/AdminBoard/AdminBoard'
import {useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {getProjects, getProjectsAll} from '../../../../redux/reducers/projectsReducer'

const Project = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getProjects())
    }, []);
    const {projectsAll} = useSelector(({projects}) => projects);
    const [data, setData] = useState(null);
    useEffect(() => {
        if (projectsAll) {
            setData(projectsAll.data)
        }
    }, [projectsAll]);

    const navigate = useNavigate();
    const url = "https://tireck-backend.herokuapp.com/admin/project";
    return (
        <div className={styles.wrapper}>
            <div className={styles.box}>
                <button onClick={() => navigate('/admin/createProject')} className={styles.btn}>Создать Проект</button>
            </div>
            <div className={styles.board}>
                {data && data.length > 0 ? <AdminBoard url={url} data={data}/> : <div>идет загрузка</div>}
            </div>
        </div>
    )
};

export default Project