import React, {useEffect, useState} from 'react';
import NewsCard from "../NewsCard";
import styles from '../RecomendNews/recomend.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {getProjectsAll} from "../../../redux/reducers/projectsReducer";
import {Link} from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";

const RecomendProjects = ({id}) => {

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getProjectsAll(localStorage.language ? localStorage.language : 1))
    }, [localStorage.language]);
    const {projects} = useSelector(({projects}) => projects);
    const [data, setData] = useState(null);
    useEffect(() => {
        if (projects && projects.data) {
            setData(projects.data.filter(el => el.id !== id))
        }
    }, [projects, id]);

    return (
        <div className={styles.column}>
            {
                data ?
                    data.slice(0, 3).map((item, idx) => (
                        <React.Fragment key={`${item.data}${idx}`}>
                            <Link
                                to={`/projects/${item?.typeDto?.id === 1 ? 'education' : item?.typeDto?.id === 2 ? 'health' : item?.typeDto?.id === 3 ? 'ecology' : item?.typeDto?.id === 4 ? 'culture' : item?.typeDto?.id === 5 ? 'science' : item?.typeDto?.id === 6 ? 'defending' : item?.typeDto?.id === 7 ? 'economic' : ''}/${item.id}`}>
                                <NewsCard id={item.id} subtitle={item.description} title={item.head}
                                          image={item.imageDtos}
                                          cardDate={item.date}/>
                            </Link>
                        </React.Fragment>
                    ))
                    : <Skeleton variant="rectangular" width={"100%"} height={200}/>
            }
        </div>
    );
};

export default RecomendProjects;