import React from 'react';
import {Helmet} from "react-helmet";

const MetaDecerotar = ({imageUrl, description, title}) => {
    return (
        <Helmet>
            <meta property="og:title" content={title}/>
            <meta property="og:type" content="article"/>
            <meta property="og:image" content={imageUrl}/>
            <meta property="og:url" content={window.location.protocol + "//" + window.location.host  + window.location.pathname}/>
            <meta name="twitter:card" content="summary_large_image"/>
            <meta property="og:description" content={description}/>
            <meta name="twitter:image:alt" content={imageUrl}/>
        </Helmet>
    );
};

export default MetaDecerotar;