import React from 'react'
import styles from "../CreateProject/styles.module.scss"
import {useState} from 'react';
import axios from "axios"
import MyModal from '../../atoms/Modal/Modal';
import {set} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';

const CreateNews = ({setOpen, setModalText}) => {

    const [files, setFiles] = useState([]);
    const [link, setLink] = useState("");

    const [text, setText] = useState("");
    const [title, setTitle] = useState("");

    const [textEn, setTextEn] = useState("");
    const [titleEn, setTitleEn] = useState("");

    const [textKg, setTextKg] = useState("");
    const [titleKg, setTitleKg] = useState("");

    const CREATE_POST_URL = "https://tireck-backend.herokuapp.com/admin/post";
    const navigate = useNavigate();


    const createNews = async (file) => {

        const formData = new FormData();
        file.forEach(element => {
            formData.append("files", element)
        });

        const dto_object = new Blob([JSON.stringify([
            {
                description: textKg,
                head: titleKg,
                youtubeLink: link,
                languageId: 1
            },
            {
                description: text,
                head: title,
                youtubeLink: link,
                languageId: 2
            },
            {
                description: textEn,
                head: titleEn,
                youtubeLink: link,
                languageId: 3
            }
        ].filter((item) => item.description !== '' && item.head !== ''))], {
            type: 'application/json'
        });

        formData.append("createPostDtos", dto_object, '');

        const response = await axios({
            method: "post",
            url: CREATE_POST_URL,
            data: formData,
            headers: {'Content-Type': 'multipart/form-data', Authorization: `Bearer ${localStorage.getItem("token")}`,}
        });
        return response

    };

    const [postLanguage, setPostLanguage] = useState(1);

    return (
        <div className={styles.wrapper}>
            <form onSubmit={(e) => {
                setOpen(true);
                setModalText('Новость создана');
                e.preventDefault();
                createNews(files)
                    .then(response => {
                        if (response.status === 200) {
                            navigate("/admin/news")
                        }
                    })
            }}>
                <div className={styles.top}>
                    <h1 className={styles.title}>Создать Новость</h1>
                    <div className={styles.topRow}>
                        <p onClick={() => setPostLanguage(1)}
                           style={{background: `${postLanguage === 1 ? '#14AD02' : 'transparent'}`}}
                           className={styles.topChild}>КЫРГ</p>
                        <p onClick={() => setPostLanguage(2)}
                           style={{background: `${postLanguage === 2 ? '#14AD02' : 'transparent'}`}}
                           className={styles.topChild}>РУС</p>
                        <p onClick={() => setPostLanguage(3)}
                           style={{background: `${postLanguage === 3 ? '#14AD02' : 'transparent'}`}}
                           className={styles.topChild}>EN</p>
                    </div>
                </div>

                <div className={styles.main}>
                    <div style={{
                        opacity: `${postLanguage !== 2 ? '0' : 'unset'}`,
                        zIndex: `${postLanguage !== 2 ? '-1' : '10'}`
                    }} className={styles.child}>
                        <div className={styles.block}>
                            <h3>Заголовок</h3>
                            <input required={true} onChange={(e) => {
                                setTitle(e.target.value)
                            }} className={styles.input} type="text" placeholder='title'/>
                        </div>
                        <div className={styles.block}>
                            <h3>Текст *</h3>
                            <textarea required={true} maxLength={4500} className={styles.input} onChange={(e) => {
                                setText(e.target.value)
                            }} name="body" id="" cols="30" rows="10">
                            </textarea>
                        </div>
                    </div>

                    <div style={{
                        opacity: `${postLanguage !== 3 ? '0' : 'unset'}`,
                        zIndex: `${postLanguage !== 3 ? '-1' : '10'}`
                    }} className={styles.child}>
                        <div className={styles.block}>
                            <h3>Заголовок</h3>
                            <input required={true} onChange={(e) => {
                                setTitleEn(e.target.value)
                            }} className={styles.input} type="text" placeholder='title'/>
                        </div>
                        <div className={styles.block}>
                            <h3>Текст *</h3>
                            <textarea required={true} className={styles.input} onChange={(e) => {
                                setTextEn(e.target.value)
                            }} name="body" id="" cols="30" rows="10">
                         </textarea>
                        </div>
                    </div>

                    <div style={{
                        opacity: `${postLanguage !== 1 ? '0' : 'unset'}`,
                        zIndex: `${postLanguage !== 1 ? '-1' : '10'}`
                    }} className={styles.child}>
                        <div className={styles.block}>
                            <h3>Заголовок</h3>
                            <input required={true} onChange={(e) => {
                                setTitleKg(e.target.value)
                            }} className={styles.input} type="text" placeholder='title'/>
                        </div>
                        <div className={styles.block}>
                            <h3>Текст *</h3>
                            <textarea required={true} className={styles.input} onChange={(e) => {
                                setTextKg(e.target.value)
                            }} name="body" id="" cols="30" rows="10">
                        </textarea>
                        </div>
                    </div>
                </div>

                <div className={styles.bot}>
                    <div className={styles.block}>
                        <h3>Видео</h3>
                        <input className={styles.input} type="text" placeholder='ссылка на видео' onChange={(e) => {
                            setLink(e.target.value)
                        }}/>
                    </div>
                    <div className={styles.block}>
                        <h3>Фотография</h3>
                        <input required={true} className={styles.file} multiple type="file" onChange={(e) => {
                            const arr = Array.from(e.target.files);
                            setFiles(arr)
                        }}/>
                    </div>
                </div>

                <div className={styles.btns}>
                    <button type="submit" className={styles.addBlog}>Сохранить</button>
                    <button onClick={() => {
                        navigate("/admin/news")
                    }} className={styles.cancel}>Назад
                    </button>
                </div>


            </form>

        </div>
    )
}

export default CreateNews