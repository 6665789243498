import React from 'react';
import styles from './contacts.module.scss';
import geo from '../../Assets/icons/geo.png';
import email from '../../Assets/icons/email.png';
import Map from "../../Сomponents/atoms/Map";
import {MaxSmall, MinSmall} from "../../utils/mediaQuiries";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const Contacts = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    return (
        <div id="contactsSection" className={styles.wrapper}>
            <div className="wrapper">
                <div className={styles.content}>
                    <h1 className={styles.title}>{t('contactTitle')}</h1>
                    <div className={styles.row}>
                        <div className={styles.box}>
                            <div>
                                <p className={styles.text}>{t('contactSubtitle1')}</p>
                                <p className={styles.text}>{t('contactSubtitle2')}</p>
                            </div>
                            <button onClick={() => navigate('/appeal')} className={styles.btn}>{t('applyButton')}</button>
                        </div>
                        <div className={styles.box}>
                            <p className={styles.info}><img src={geo} alt=""/>{t('contactAddress')}</p>
                            <a href="mailto:enebalagatirek@gmail.com" className={styles.info}><img src={email} alt=""/>enebalagatirek@gmail.com</a>
                            {/*<a href="mailto:tirek.inbox@gmail.com" className={styles.info}><img src={email} alt=""/>tirek.inbox@gmail.com</a>*/}
                            <div className={styles.icons}>
                                <a target="_blank" href="https://instagram.com/enebalagatirek?igshid=YmMyMTA2M2Y=">
                                    <i className="ri-instagram-line"/>
                                </a>
                                <a target="_blank" href="https://www.facebook.com/enebalagatirek/?hc_ref=ARQ3_9IK0b5nJMz-6cmhPwgvb7cfCsHgaCOetEY_RTdKCunTXqx5JaD8JxPIy748oXM&fref=nf&__xts__[0]=68.ARAV8MQIU1prvTKJtd3G-67UZJ0MmSk56vJFBL3xJbfEulXB1qM5wuR8JM0gTT2g3L-AjrrpqAg0UGdjWJoujLhHJQbMgDEqS9I8QYWJZXPvwT93abKGuB0T-jRVYjjsPEju1xjSJGUp2DJm1botdW8rt6wdw30ehltKKbozsREUzHH6-Ts_2lq5OthcwJq6f-kK-Y6f21HveeDVRUAa-FEYaZf0Vj_-LfCNDPCK5MtllsiYZy4AYgX30cGawxEusU0QCIt2elRrI9SBPFucIu1QlTA2pRSm54FDlJsxPEChTE6pKt8">
                                    <i className="ri-facebook-box-fill"/>
                                </a>
                                <a target="_blank" href="https://www.youtube.com/">
                                    <i className="ri-youtube-fill"/>
                                </a>
                                <a target="_blank" href="https://twitter.com/EneBalagaTirek">
                                    <i className="ri-twitter-fill"/>
                                </a>
                            </div>
                            <MinSmall>
                                <Map height={220} width={'100%'}/>
                            </MinSmall>
                            <MaxSmall>
                                <Map height={150} width={'100%'}/>
                            </MaxSmall>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contacts;