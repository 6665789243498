import {Subscribe} from '../api';

const initialState = {
    newSubscribe: {}
};

const subscribe = (state = initialState, action) => {
    switch (action.type) {
        case "SUBSCRIBE": {
            return {
                ...state,
                newSubscribe: action.data
            }
        }
        default:
            return state
    }
};

export const setDataSendEmail = (data) => ({type: "SUBSCRIBE", data});

export const leaveSubscribe = (data) => (dispatch) => {
    Subscribe.postEmail(data).then((response) =>
        dispatch(
            setDataSendEmail({
                data: response.data
            })
        )
    );
};

export default subscribe;