import React, {useEffect, useState} from 'react'
import api from '../../../../Requests/axios'
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {getNewsById} from '../../../../redux/reducers/newsReducer';
import styles from "./styles.module.scss"
import axios from "axios"
import {useNavigate} from 'react-router-dom';


const EditNews = ({setOpen, setModalText}) => {
    const {id} = useParams();
    const dispatch = useDispatch();
    const [text, setText] = useState("");
    const [files, setFiles] = useState([]);
    const [title, setTitle] = useState("");
    const [link, setLink] = useState("");

    const [imageId, setImageId] = useState(null);

    useEffect(() => {
        dispatch(getNewsById(id))
    }, []);

    const {newsById} = useSelector(({news}) => news);
    const [data, setData] = useState(null);
    useEffect(() => {
        if (newsById) {
            setData(newsById.data)
        }
    }, [newsById]);

    const updatePostUrl = `https://tireck-backend.herokuapp.com/admin/post`;
    const updateNews = async (e, file = data.imageDtos, description, head, videoLink, newsId) => {

        if (imageId !== null) {
            let filtered = [];
            const formData = new FormData();
            filtered = data.imageDtos.filter((item) => {
                return item.id === imageId
            });
            const deletedImg = new Blob([JSON.stringify([filtered[0].id])], {type: 'application/json'});
            formData.append("imageIds", deletedImg);
            //
            const dto_object = new Blob([JSON.stringify([{
                id: +newsId,
                date: data.date,
                head: e.target[0].value,
                description: e.target[1].value,
                youtubeLink: e.target[2].value,
                languageId: data?.languageDto?.id
                // imageIds: filtered.map((item) => item.id)
            }])], {
                type: 'application/json'
            });
            formData.append("updatePostDtos", dto_object, '');

            const response = await axios({
                method: "put",
                url: updatePostUrl,
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                }
            }).then(() => {
                setImageId(null);
            });
            return response
        } else {
            const formData = new FormData();
            file.forEach(element => {
                formData.append("files", element)
            });
            console.log(file);
            const dto_object = new Blob([JSON.stringify([{
                id: +newsId,
                date: data.date,
                head: e.target[0].value,
                description: e.target[1].value,
                youtubeLink: e.target[2].value,
                languageId: data?.languageDto?.id
                // documentIds: data.imageDtos.map((item) => item.id)
            }])], {
                type: 'application/json'
            });

            formData.append("updatePostDtos", dto_object, '');

            const response = await axios({
                method: "put",
                url: updatePostUrl,
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                }
            });
            navigate('/admin/news');
            return response
        }
    };

    const navigate = useNavigate();
    return (
        <div className={styles.wrapper}>
            <h1 className={styles.title}>Обновить новость</h1>
            <form onSubmit={(e) => {
                e.preventDefault();
                updateNews(e, files, text, title, link, id).then(() => {
                    document.location.reload()
                });
            }}>
                <div className={styles.block}>
                    <h3>Заголовок</h3>
                    <input required={true} defaultValue={data ? data.head : ''} onChange={(e) => {
                        setTitle(e.target.value)
                    }} type="text" placeholder='title'/>
                </div>
                <div className={styles.block}>
                    <h3>Текст *</h3>
                    <textarea maxLength={4500} required={true} defaultValue={data ? data.description : ''}
                              onChange={(e) => {
                                  setText(e.target.value)
                              }} name="body" id="" cols="30" rows="10">
                </textarea>
                </div>
                <div className={styles.block}>
                    <h3>Видео</h3>
                    <input defaultValue={data ? data.youtubeLink : ''} type="text"
                           onChange={(e) => {
                               setLink(e.target.value)
                           }}/>
                </div>
                <div className={styles.fileBox}>
                    <div className={styles.block}>
                        <h3>Фотография</h3>
                        <input required={!data?.imageDtos.length > 0 ? true : false} className={styles.file} multiple
                               type="file"
                               onChange={(e) => {
                                   const arr = Array.from(e.target.files);
                                   console.log(arr)
                                   setFiles(arr)
                               }}/>

                    </div>
                    <div className={styles.box}>
                        {
                            data ?
                                data.imageDtos.map((item) => (
                                    <div>
                                        <button type="submit" onClick={() => {
                                            setImageId(item.id);
                                        }}>
                                            <i className="ri-close-circle-fill"/>
                                        </button>
                                        <img src={item.url} alt=""/>
                                    </div>
                                ))
                                : ''
                        }
                    </div>
                </div>
                <div className={styles.btns}>
                    <button type="submit" className={styles.addBlog}>Сохранить</button>
                    <button onClick={() => {
                        if (data.imageDtos.length > 0) {
                            navigate("/admin/news")
                        }
                    }} className={styles.cancel}>Назад
                    </button>
                </div>
            </form>
        </div>
    )
}

export default EditNews