import React from 'react'
import AdminBoard from '../../../../Сomponents/moleculas/AdminBoard/AdminBoard'
import styles from "../ProjectsPages/styles.module.scss"
import {useNavigate} from 'react-router-dom'
import {NavLink as Link} from "react-router-dom"

const News = ({data}) => {
    const navigate = useNavigate();
    const url = "https://tireck-backend.herokuapp.com/admin/post";
    return (
        <div className={styles.wrapper}>
            <div className={styles.box}>
                <button onClick={() => navigate('/admin/createNews')} className={styles.btn}>Создать Новость</button>
            </div>
            <div className={styles.board}>
                {data && data.length > 0 ? <AdminBoard url={url} data={data}/> : <div>идет загрузка</div>}
            </div>
        </div>
    )
};

export default News