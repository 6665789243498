import React from 'react';
import styles from "../../../Sections/OurProjects/ourProjects.module.scss";
import {useTranslation} from "react-i18next";

const CategoriesList = ({category, setCategory}) => {
    const {t} = useTranslation();
    return (
        <ul className={styles.list}>
            <li onClick={() => setCategory(7)}
                className={category === 7 ? styles.active : styles.item}><i
                className="ri-arrow-right-s-fill"/>
                {t('servicesListTitle3')}
            </li>
            <li onClick={() => setCategory(2)}
                className={category === 2 ? styles.active : styles.item}><i
                className="ri-arrow-right-s-fill"/>
                {t('servicesListTitle2')}
            </li>
            <li onClick={() => setCategory(6)}
                className={category === 6 ? styles.active : styles.item}><i
                className="ri-arrow-right-s-fill"/>{t('servicesListTitle7')}
            </li>
            <li onClick={() => setCategory(1)}
                className={category === 1 ? styles.active : styles.item}><i
                className="ri-arrow-right-s-fill"/>{t('servicesListTitle1')}
            </li>
            <li onClick={() => setCategory(3)}
                className={category === 3 ? styles.active : styles.item}><i
                className="ri-arrow-right-s-fill"/>{t('servicesListTitle4')}
            </li>
            <li onClick={() => setCategory(4)}
                className={category === 4 ? styles.active : styles.item}><i
                className="ri-arrow-right-s-fill"/>{t('servicesListTitle5')}
            </li>
            <li onClick={() => setCategory(5)}
                className={category === 5 ? styles.active : styles.item}><i
                className="ri-arrow-right-s-fill"/>{t('servicesListTitle6')}
            </li>
        </ul>
    );
};

export default CategoriesList;