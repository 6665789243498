import React from 'react';
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import {Navigation} from "swiper";
import {NewsCard} from "../index";
import {Link} from 'react-router-dom';
import fourteen from '../../../Assets/images/gallery/fourteen.JPG';
import one from '../../../Assets/images/gallery/one.jpg';
import styles from "../../../Sections/NewsMain/newsMain.module.scss";

const SwiperCards = ({page, category, data, margin, padding, breakpoints, cardClass}) => {

    return (
        <div className={padding}>
            <Swiper navigation={true} modules={[Navigation]} spaceBetween={margin}
                    breakpoints={breakpoints}
                    className="mySwiper">
                {
                    page ? (data && data.length > 0 ? data.slice(0, 12).map((item, idx) => (
                                <SwiperSlide>
                                    <Link
                                        to={`${item?.typeDto ? `projects/${item?.typeDto?.type === '1' ? 'education' : item?.typeDto?.type === '2' ? 'health' : item?.typeDto?.type === '3' ? 'ecology' : item?.typeDto?.type === '4' ? 'culture' : item?.typeDto?.type === '5' ? 'science' : item?.typeDto?.type === '6' ? 'defending' : item?.typeDto?.type === '7' ? 'economic' : ''}`
                                            : ''}/${item.id}`}>
                                        <NewsCard cardClass={cardClass} width={'100%'} title={item.head}
                                                  image={item.imageDtos[0].url}
                                                  subtitle={item.description.length > 120 ? `${item.description.substr(0, 120)}...`
                                                      : item.description.substr(0, 120)}/>
                                    </Link>
                                </SwiperSlide>
                            ))
                            : ''
                        )
                        : (data && data.length > 0 ? data.slice(-4).map((item, idx) => (

                                <SwiperSlide>
                                    <Link to={`news/${item.id}`}>
                                        <NewsCard cardClass={cardClass} width={'100%'} title={item.head}
                                                  cardDate={item.date}
                                                  image={item.imageDtos[0]?.url}
                                                  subtitle={item.description}/>
                                    </Link>
                                </SwiperSlide>
                            ))
                            : ''
                        )
                }
            </Swiper>
        </div>
    );
};

export default SwiperCards;