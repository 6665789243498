import React, {useState} from 'react'
import logo from '../../../Assets/images/logo.png';
import {Link} from 'react-router-dom';
import './footer.css';
import {useTranslation} from "react-i18next";
import axios from "axios";
import enLogo from "../../../Assets/icons/ENEbalagatirekENGLogo.png";
import {useDispatch} from "react-redux";

const Footer = () => {
    const {t} = useTranslation();

    const [message, setMessage] = useState(null);

    const postAppealUrl = 'https://tireck-backend.herokuapp.com/subscribe';

    const createNews = async () => {
        const formData = new FormData();
        const data = new Blob([JSON.stringify({
            text: "subscribe email",
            email: message
        })], {
            type: 'application/json'
        });

        formData.append("submissionFormDto", data);

        const response = await axios({
            method: "post",
            url: postAppealUrl,
            data: formData,
            headers: {'Content-Type': 'multipart/form-data'}
        });

        return response
    };

    // const dispatch = useDispatch();
    //
    // const leaveSubscribeHandler = () => {
    //     const data = {
    //         text: "subscribe message",
    //         email: message
    //     };
    //     dispatch(leaveSubscribe(data))
    // };

    return (
        <footer className="footer">
            <div className="wrapper">
                <div className="footer__row">
                    <img className='footer__logo' src={localStorage.language === '3' ? enLogo : logo} alt="Tirek"/>
                    <ul className="footer__menu">
                        <h2 className="footer__title">{t('footerMenuTitle1')}</h2>
                        <li>
                            <Link to="/news">{t('footerMenuLink1')}</Link>
                        </li>
                        <li>
                            <Link to="/projects/education">{t('footerMenuLink2')}</Link>
                        </li>
                        <li>
                            <Link to="/appeal">{t('footerMenuLink3')}</Link>
                        </li>
                    </ul>
                    {/*<div className="footer__menu">*/}
                    {/*    <ul>*/}
                    {/*        <h2 className="footer__title">Проекты</h2>*/}
                    {/*        <li>*/}
                    {/*            <Link to="/">Образование</Link>*/}
                    {/*        </li>*/}
                    {/*        <li>*/}
                    {/*            <Link to="/">Здравоохранение</Link>*/}
                    {/*        </li>*/}
                    {/*        <li>*/}
                    {/*            <Link to="/">Правовая защита</Link>*/}
                    {/*        </li>*/}
                    {/*    </ul>*/}
                    {/*</div>*/}
                    <ul className="footer__menu">
                        <h2 className="footer__title">{t('footerMenuTitle2')}</h2>
                        <li>
                            <p>{t('footerAddress1')}</p>
                        </li>
                        <li>
                            <p>{t('footerAddress2')}</p>
                        </li>
                        <li>
                            <a href="mailto:enebalagatirek@gmail.com">enebalagatirek@gmail.com</a>
                        </li>
                    </ul>
                    <div className="footer__form">
                        <h2 className="footer__title">{t('footerSubscribe')}</h2>
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            createNews()
                                .then((error) => null);
                            e.target[0].value = ''
                        }}>
                            <label>
                                <input required={true} onChange={(e) => setMessage(e.target.value)}
                                       className="footer__input" placeholder="E-mail" type="email"/>
                            </label>
                            <button type="submit" className="footer__btn">{t('footerSubscribeButton')}</button>
                        </form>
                    </div>
                </div>
                <div className="footer__contact">
                    <div className="footer__left">
                        <Link to="/">Terms</Link>
                        <Link to="/">Privacy</Link>
                        <Link to="/">License</Link>
                    </div>
                    {/*<p className="footer__center">Copyright ©2022 Эне-балага тирек | This web-site is made by*/}
                    {/*    Fortylines.io</p>*/}
                    <div className="footer__right">
                        <p>{t('footerFollow')}</p>
                        <div className="footer__icons">
                            <a target="_blank" href="https://instagram.com/enebalagatirek?igshid=YmMyMTA2M2Y=">
                                <i className="ri-instagram-line"/>
                            </a>
                            <a target="_blank"
                               href="https://www.facebook.com/enebalagatirek/?hc_ref=ARQ3_9IK0b5nJMz-6cmhPwgvb7cfCsHgaCOetEY_RTdKCunTXqx5JaD8JxPIy748oXM&fref=nf&__xts__[0]=68.ARAV8MQIU1prvTKJtd3G-67UZJ0MmSk56vJFBL3xJbfEulXB1qM5wuR8JM0gTT2g3L-AjrrpqAg0UGdjWJoujLhHJQbMgDEqS9I8QYWJZXPvwT93abKGuB0T-jRVYjjsPEju1xjSJGUp2DJm1botdW8rt6wdw30ehltKKbozsREUzHH6-Ts_2lq5OthcwJq6f-kK-Y6f21HveeDVRUAa-FEYaZf0Vj_-LfCNDPCK5MtllsiYZy4AYgX30cGawxEusU0QCIt2elRrI9SBPFucIu1QlTA2pRSm54FDlJsxPEChTE6pKt8">
                                <i className="ri-facebook-box-fill"/>
                            </a>
                            <a target="_blank" href="https://www.youtube.com/channel/UCl5QJjQV_Cj3faFSrKC54Fg">
                                <i className="ri-youtube-fill"/>
                            </a>
                            <a target="_blank" href="https://twitter.com/EneBalagaTirek">
                                <i className="ri-twitter-fill"/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
};

export default Footer