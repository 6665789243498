import React from 'react'
import styles from "./AboutUs.module.scss"
import {AboutUsBlog} from '../../Сomponents/atoms'
import AboutCreator from './AboutCreator/AboutCreator'
import {useTranslation} from "react-i18next";

const AboutUs = () => {
    const {t} = useTranslation();
    return (
        <div className={styles.wrapper}>
            <div className="wrapper">
                <div className={styles.content_wrapper}>
                    <div className={styles.firstPart}>
                        <AboutUsBlog title={t('aboutUsTitle')}
                                     textBody={t('aboutUsSubtitle')}/>
                        <AboutUsBlog title={t('aboutUsMission')}
                                     textBody={t('aboutUsMainMission')}/>
                    </div>
                    <div className={styles.secondPart}>
                        <AboutCreator/>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default AboutUs