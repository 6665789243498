import React, {useEffect, useState} from 'react';
import styles from './newsMain.module.scss';
import './newsMain.css';
import "swiper/css";
import "swiper/css/navigation";
import SwiperCards from "../../Сomponents/atoms/SwiperCards";
import NavigateBtn from "../../Сomponents/atoms/NavigateBtn";
import {Max768, Min768} from "../../utils/mediaQuiries";
import {useDispatch, useSelector} from "react-redux";
import {getNewsAll} from "../../redux/reducers/newsReducer";
import {useTranslation} from "react-i18next";

const NewsMain = () => {
    const swiperBreakpoints = {
        1600: {
            slidesPerView: 4,
            spaceBetween: 50,
        },
        921: {
            slidesPerView: 3,
            spaceBetween: 30,
        },
        850: {
            slidesPerView: 3,
            spaceBetween: 20,
        },
        650: {
            slidesPerView: 2,
            spaceBetween: 15,
        },
        375: {
            slidesPerView: 1,
        }
    };

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getNewsAll(localStorage.language ? localStorage.language : 1))
    }, [localStorage.language]);
    const {t} = useTranslation();

    const {news} = useSelector(({news}) => news);
    const [data, setData] = useState(null);
    useEffect(() => {
        if (news) {
            setData(news.data)
        }
    }, [news]);

    return (
        <div className={styles.wrapper}>
            <div className="wrapper mainSwiper">
                <h2 className={styles.title}>{t('newsTitle')}</h2>
                <p className={styles.subtitle}>{t('newsSubtitle')}</p>
                <Max768>
                    <NavigateBtn address={'/news'} text={t('allNewsText')}/>
                </Max768>
                <SwiperCards cardClass={'mainPageNewsCards'} data={data ? data.reverse() : ''}
                             padding={styles.container} margin={50}
                             breakpoints={swiperBreakpoints}/>
                <Min768>
                    <div className={styles.btnBox}>
                        <NavigateBtn address={'/news'} text={t('allNewsText')}/>
                    </div>
                </Min768>
            </div>
        </div>
    );
};

export default NewsMain;