import React, {useState} from 'react'
import style from "../../../Sections/OurProjects/ourProjects.module.scss";
import styles from "./styles.module.scss"
import {Link, useNavigate, useParams} from 'react-router-dom'
import {Max768, MaxMedium, Min768, MinMedium} from "../../../utils/mediaQuiries";
import {useTranslation} from "react-i18next";

const SubHeader = () => {

    const {t} = useTranslation();

    const link = [
        {
            text: `${t('servicesListTitle1')}`,
            path: "/education",
            id: 1
        },
        {
            text: `${t('servicesListTitle2')}`,
            path: "/health",
            id: 2
        },
        {
            text: `${t('servicesListTitle4')}`,
            path: "/ecology",
            id: 3
        },
        {
            text: `${t('servicesListTitle5')}`,
            path: "/culture",
            id: 4
        },
        {
            text: `${t('servicesListTitle6')}`,
            path: "/science",
            id: 5
        },
        {
            text: `${t('servicesListTitle7')}`,
            path: "/defending",
            id: 6
        },
        {
            text: `${t('servicesListTitle3')}`,
            path: "/economic",
            id: 7
        },
    ];

    const [categoryOpen, setCategoryOpen] = useState(false);
    const navigate = useNavigate();

    const menuHandler = (item) => {
        if (categoryOpen) {
            navigate(`/projects${item.path}`)
        }
        localStorage.setItem('projectsCategory', item.id);
        localStorage.setItem('projectsCategoryPath', item.path);
        setCategoryOpen(!categoryOpen);
    };

    const menuHandlerPC = (item) => {
        navigate(`/projects${item.path}`);
        localStorage.setItem('projectsCategory', item.id);
        localStorage.setItem('projectsCategoryPath', item.path);
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.content_wrapper}>
                <MaxMedium>
                    <div className={categoryOpen ? styles.mainOpen : styles.mainClose}>
                        <div className={styles.box}>
                            {
                                link.map((item, idx) => (
                                    <p
                                        key={`${idx}${item.text}`}
                                        onClick={() => menuHandler(item)}
                                        style={{order: `${document.location.pathname.slice(9).includes(item.path) ? '-1' : 'unset'}`}}
                                        className={document.location.pathname.slice(9).includes(item.path) ? styles.active : styles.link}>
                                        <i className="ri-arrow-right-s-fill"/>{item.text}
                                    </p>
                                ))
                            }
                        </div>
                    </div>
                </MaxMedium>
                <MinMedium>
                    {link.map((item, idx) => {
                        return <p onClick={() => menuHandlerPC(item)} key={`${idx}${item.text}`}
                                  className={document.location.pathname.slice(9).includes(item.path) ? styles.active : styles.link}>{item.text}</p>
                    })}
                </MinMedium>
            </div>

        </div>
    )
}

export default SubHeader