import i18n from "i18next";
import {initReactI18next} from "react-i18next";

import translationRU from './Assets/locales/ru/translation';
import translationEN from './Assets/locales/en/translation';
import translationKG from './Assets/locales/kg/translation';

// the translations
const resources = {
    "2": {
        translation: translationRU
    },
    "3": {
        translation: translationEN
    },
    "1": {
        translation: translationKG
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: "1",

        keySeparator: false,

        interpolation: {
            escapeValue: false
        }
    });

export default i18n;