const initialState = {
    loading: false,
    error: null
};

const loader = (state = initialState, action) => {
    switch (action.type) {
        case 'LOADER_DISPLAY_ON':
            return {
                ...state,
                loading: true
            };
        case 'LOADER_DISPLAY_OFF':
            return {
                ...state,
                loading: false
            };
        default:
            return state
    }
};

export const loaderOn = () => {
    return {
        type: 'LOADER_DISPLAY_ON'
    }
};

export const loaderOff = () => {
    return {
        type: 'LOADER_DISPLAY_OFF'
    }
};

export default loader;