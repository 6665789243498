import {Images} from '../api';
const initialState = {
    images: {},
};

const images = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ALL_IMAGES': {
            return {
                ...state,
                images: action.data
            }
        }
        default:
            return state
    }
};

export const setImages = (data) => ({type: 'GET_ALL_IMAGES', data});

export const getImagesAll = () => async (dispatch) => {
    await Images.getImages().then((response) => {
        dispatch(setImages(response));
    })
};

export default images;