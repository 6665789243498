import axios from "axios";

export const instanceNotAuthorized = axios.create({
    // baseURL: `http://46.101.166.144:8080`,
    baseURL: `https://tireck-backend.herokuapp.com`,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=UTF-8",
    },
});

export const News = {
    getNews(language) {
        return instanceNotAuthorized
            .get(`/post/getAll/${language}`)
            .then((response) => {
                return response
            })
            .catch((error) => {
                return error.response
            });
    },
    getAllNews() {
        return instanceNotAuthorized
            .get(`/post/getAll`)
            .then((response) => {
                return response
            })
            .catch((error) => {
                return error.response
            });
    },
    getNewsById(id, language) {
        return instanceNotAuthorized
            .get(`/post/getBy/${id}${language ? `/${language}` : ''}`)
            .then((response) => {
                return response
            })
            .catch((error) => {
                return error.response
            });
    },
};

export const Projects = {
    getProjects(language, type) {
        return instanceNotAuthorized
            .get(`/project/getAll/${language}${type ? `/${type}` : ''}`)
            .then((response) => {
                return response
            })
            .catch((error) => {
                return error.response
            });
    },
    getAllProjects() {
        return instanceNotAuthorized
            .get(`/project/getAll`)
            .then((response) => {
                return response
            })
            .catch((error) => {
                return error.response
            });
    },
    getProjectsById(id, language) {
        return instanceNotAuthorized
            .get(`/project/getBy/${id}${language ? `/${language}` : ''}`)
            .then((response) => {
                return response
            })
            .catch((error) => {
                return error.response
            });
    },
};

export const Images = {
    getImages() {
        return instanceNotAuthorized
            .get('/image/getAll')
            .then((response) => {
                return response
            })
            .catch((error) => {
                return error.response
            });
    }
};

export const Subscribe = {
    postEmail(data) {
        return instanceNotAuthorized
            .post('/subscribe', data)
            .then((response) => {
                return response
            })
            .catch((error) => {
                return error.response
            })
    }
};