import React, {useEffect, useState} from 'react';
import styles from '../ProjectItem/item.module.scss';
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import {Pagination} from "swiper";
import "swiper/css/navigation";
import {Navigation} from "swiper";
import img from '../../Assets/images/projectImage.png'
import '../ProjectItem/projectNewsItem.css';
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getNewsById} from "../../redux/reducers/newsReducer";
import RecomendNews from "../../Сomponents/atoms/RecomendNews";
import {useTranslation} from "react-i18next";
import MetaDecerotar from "../../utils/metaDecerotar";
import Skeleton from "@mui/material/Skeleton";

const NewsItem = () => {

    const {id} = useParams();

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getNewsById(id, localStorage.language ? localStorage.language : 1))
    }, [id, localStorage.language]);

    const {newsById} = useSelector(({news}) => news);
    const [data, setData] = useState(null);
    useEffect(() => {
        if (newsById) {
            setData(newsById.data)
        }
    }, [newsById]);
    const {t} = useTranslation();

    return (
        <>
            <MetaDecerotar title={data ? data.head : ''} description={data ? data.description : ''}
                           imageUrl={data ? data.imageDtos[0].url : ''}/>
            <div className="wrapper projectNewsItem">
                {data
                    ? <div className={styles.newsWrapper}>
                        <p className={styles.date}>
                            {new Intl.DateTimeFormat('ru-Ru', {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                            }).format(new Date(data.date))}
                        </p>
                        <h1 className={styles.title}>{data.head}</h1>
                        <div className={styles.row}>
                            <div className={styles.left}>
                                <div className={styles.box}>
                                    <Swiper pagination={true} navigation={true} modules={[Pagination, Navigation]}
                                            className="mySwiper">
                                        {
                                            data?.imageDtos.map((item, idx) => (
                                                <React.Fragment key={`${idx}${item.id}`}>
                                                    <SwiperSlide>
                                                        <img src={item.url} alt=""/>
                                                    </SwiperSlide>
                                                </React.Fragment>
                                            ))
                                        }
                                    </Swiper>
                                </div>
                                <div className={styles.textBox}>
                                    {data.description.split('\n').map((item) => (
                                        item.includes('   ')
                                            ? <p style={{textIndent: "6%"}} className={styles.text}>{item}</p>
                                            : <p className={styles.text}>{item}</p>
                                    ))
                                    }
                                </div>
                                {
                                    data.youtubeLink ?
                                        // <p style={{color: '#000000', margin: '8% 0 0'}} className={styles.text}>
                                        //     {t('newsItemPageVideoLink')}
                                        //     <a target="_blank" style={{color: '#0A70A9', margin: '0 0 0 10px'}}
                                        //        href={data ? data.youtubeLink : ''}>{data.youtubeLink}</a>
                                        // </p>
                                        <iframe className={styles.video}
                                                src={data.youtubeLink}
                                                title="YouTube video player" frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen/>
                                        : ''
                                }
                                <div className={styles.share}>
                                    <p>{t('share')}:</p>
                                    <div className={styles.shareRow}>
                                        <div className={styles.iconBox}>
                                            <a target="_blank"
                                               href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.protocol + "//" + window.location.host + window.location.pathname}%2F`}>
                                                <i className="ri-facebook-fill"/>
                                            </a>
                                        </div>
                                        <div className={styles.iconBox}>
                                            <a target="_blank"
                                               href={`https://twitter.com/intent/tweet?text=${data.head}&url=${window.location.protocol + "//" + window.location.host + window.location.pathname}%2F`}>
                                                <i className="ri-twitter-line"/>
                                            </a>
                                        </div>
                                        <div className={styles.iconBoxTg}>
                                            <a target="_blank"
                                               href={`https://telegram.me/share/url?url=${window.location.protocol + "//" + window.location.host + window.location.pathname}%2F&text=${data.head}`}>
                                                <i className="ri-telegram-fill"/>
                                            </a>
                                        </div>
                                        <div className={styles.iconBox}>
                                            <a target="_blank"
                                               href={`https://api.whatsapp.com/send?text=${data.head}%20${window.location.protocol + "//" + window.location.host + window.location.pathname}`}>
                                                <i className="ri-whatsapp-line"/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.right}>
                                <p className={styles.other}>{t('newsItemPageWatch')}</p>
                                <RecomendNews id={data.id}/>
                            </div>
                        </div>
                    </div>
                    : <Skeleton variant="rectangular" width={"100%"} height={200}/>
                }
            </div>
        </>
    );
};

export default NewsItem;