import {createStore, compose, applyMiddleware} from "redux";
import thunk from "redux-thunk";
import {rootReducer} from "./Reducers/index";


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

export default store


// old
// import { createStore,applyMiddleware } from "redux";
// import thunk from "redux-thunk";
// import { rootReducer } from "./Reducers/index";
//
//
//
//
// const store = createStore(rootReducer,applyMiddleware(thunk));
//
// export default store