import React, {useEffect, useState} from 'react';
import styles from './item.module.scss';
import SubHeader from "../../Сomponents/moleculas/SubHeader/SubHeader";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import {Pagination} from "swiper";
import "swiper/css/navigation";
import {Navigation} from "swiper";
import img from '../../Assets/images/projectImage.png'
import NewsCard from "../../Сomponents/atoms/NewsCard";
import './projectNewsItem.css';
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getProjectsById} from "../../redux/reducers/projectsReducer";
import RecomendProjects from "../../Сomponents/atoms/RecomendProjects";
import {useTranslation} from "react-i18next";
import Skeleton from "@mui/material/Skeleton";

const ProjectItem = () => {

    const {id} = useParams();

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getProjectsById(id, localStorage.language ? localStorage.language : '1'))
    }, [id, localStorage.language]);

    const {projectsById} = useSelector(({projects}) => projects);
    const [data, setData] = useState(null);
    useEffect(() => {
        if (projectsById) {
            setData(projectsById.data)
        }
    }, [projectsById]);

    const {t} = useTranslation();

    return (
        <div>
            <div className="wrapper projectNewsItem">
                <SubHeader/>
                <div className={styles.wrapper}>
                    {
                        data ?
                            <>
                                <h1 className={styles.title}>{data.head}</h1>
                                <div className={styles.row}>
                                    <div className={styles.left}>
                                        <div className={styles.box}>
                                            <Swiper pagination={true} navigation={true}
                                                    modules={[Pagination, Navigation]}
                                                    className="mySwiper">
                                                {
                                                    data?.imageDtos.map((item, idx) => (
                                                        <React.Fragment key={`${idx}${item.id}`}>
                                                            <SwiperSlide>
                                                                <img src={item.url} alt=""/>
                                                            </SwiperSlide>
                                                        </React.Fragment>
                                                    ))
                                                }
                                            </Swiper>
                                        </div>
                                        <div className={styles.textBox}>
                                            {data.description.split('\n').map((item) => (
                                                item.includes('   ')
                                                    ? <p style={{textIndent: "6%"}} className={styles.text}>{item}</p>
                                                    : <p className={styles.text}>{item}</p>
                                            ))
                                            }
                                        </div>
                                        {
                                            data.youtubeLink ?
                                                // <p style={{color: '#000000', margin: '8% 0 0'}} className={styles.text}>
                                                //     {t('newsItemPageVideoLink')}
                                                //     <a target="_blank" style={{color: '#0A70A9', margin: '0 0 0 10px'}}
                                                //        href={data ? data.youtubeLink : ''}>{data.youtubeLink}</a>
                                                // </p>
                                                <iframe className={styles.video}
                                                        src={data.youtubeLink}
                                                        title="YouTube video player" frameBorder="0"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                        allowFullScreen/>
                                                : ''
                                        }
                                        <div className={styles.share}>
                                            <p>{t('share')}:</p>
                                            <div className={styles.shareRow}>
                                                <div className={styles.iconBox}>
                                                    <a target="_blank"
                                                       href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.protocol + "//" + window.location.host + window.location.pathname}%2F`}>
                                                        <i className="ri-facebook-fill"/>
                                                    </a>
                                                </div>
                                                <div className={styles.iconBox}>
                                                    <a target="_blank"
                                                       href={`https://twitter.com/intent/tweet?text=${data.head}&url=${window.location.protocol + "//" + window.location.host + window.location.pathname}%2F`}>
                                                        <i className="ri-twitter-line"/>
                                                    </a>
                                                </div>
                                                <div className={styles.iconBoxTg}>
                                                    <a target="_blank"
                                                       href={`https://telegram.me/share/url?url=${window.location.protocol + "//" + window.location.host + window.location.pathname}%2F&text=${data.head}`}>
                                                        <i className="ri-telegram-fill"/>
                                                    </a>
                                                </div>
                                                <div className={styles.iconBox}>
                                                    <a target="_blank"
                                                       href={`https://api.whatsapp.com/send?text=${data.head}%20${window.location.protocol + "//" + window.location.host + window.location.pathname}`}>
                                                        <i className="ri-whatsapp-line"/>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.right}>
                                        <p className={styles.other}>{t('projectsItemPageWatch')}</p>
                                        <RecomendProjects id={data.id}/>
                                    </div>
                                </div>
                            </>
                            : <Skeleton variant="rectangular" width={"100%"} height={200}/>
                    }
                </div>
            </div>
        </div>
    );
};

export default ProjectItem;