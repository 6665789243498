import React, {useEffect, useState} from 'react';
import './gallery.css';
import NavigateBtn from "../../Сomponents/atoms/NavigateBtn";
import {MaxSmall, MinSmall} from "../../utils/mediaQuiries";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import {Pagination} from "swiper";
import {Fancybox} from "@fancyapps/ui";
import {useDispatch, useSelector} from "react-redux";
import {getImagesAll} from "../../redux/reducers/imagesReducer";
import {useTranslation} from "react-i18next";
import {Button} from "@mui/material";

const Gallery = ({page}) => {

    const [slicer, setSlicer] = useState(21);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getImagesAll())
    }, []);
    const {images} = useSelector(({images}) => images);
    const [data, setData] = useState(null);
    useEffect(() => {
        if (images) {
            setData(images.data)
        }
    }, [images]);
    const {t} = useTranslation();
    return (
        <>
            {
                page ? <div className="gallery">
                        <div className="wrapper">
                            <h1 className="gallery__title">{t('galleryTitle')}</h1>
                            <div className="gallery__content">
                                {data && data.length > 0 ?
                                    data.slice(0, slicer).map((item, idx) => (
                                        <div className="gallery__box">
                                            <img data-fancybox="gallery" data-src={item} src={item} alt=""/>
                                        </div>
                                    ))
                                    : ''
                                }
                            </div>
                            <div className="gallery__btnBox">
                                <Button onClick={() => setSlicer(slicer + 12)} className="gallery__btn"
                                        variant='text'>
                                    {t('allPhotosText')}
                                    <i className="ri-arrow-right-s-line"/>
                                </Button>
                            </div>
                        </div>
                    </div>
                    : <>
                        <div className="wrapper">
                            <div className="gallery__main_wrapper">
                                <h1 className="gallery__title">{t('galleryTitle')}</h1>
                                <NavigateBtn address={'/gallery'} text={t('allPhotosText')}/>
                                <MinSmall>
                                    <div className='gallery__main_content'>
                                        {data && data.length > 0 ?
                                            data.reverse().slice(0, 3).map((item, idx) => (
                                                <div className="gallery__box">
                                                    <img data-fancybox="gallery" data-src={item} src={item} alt=""/>
                                                </div>
                                            ))
                                            : ''
                                        }
                                    </div>
                                </MinSmall>
                                <MaxSmall>
                                    <div className="gallery__swiper">
                                        <Swiper pagination={true} modules={[Pagination]} slidesPerView={1}
                                                className="mySwiper">
                                            {data && data.length > 0 ?
                                                data.reverse().map((item) => (
                                                    <SwiperSlide>
                                                        <div className="gallery__swiper_box">
                                                            <img className="gallery__swiper_img" src={item} alt=""/>
                                                        </div>
                                                    </SwiperSlide>
                                                ))
                                                : ''
                                            }
                                        </Swiper>
                                    </div>
                                </MaxSmall>
                            </div>
                        </div>
                    </>
            }
        </>
    );
};

export default Gallery;