import React from 'react'
import styles from "./styles.module.scss"
import {final1, final2, final3, final4, final5, final6, final7} from '../../Assets/icons'
import {Service} from '../../Сomponents/atoms'
import {useTranslation} from "react-i18next";


const Services = () => {
    const {t} = useTranslation();
    const info = [
        {
            title: `${t('servicesListTitle1')}`,
            text: `${t('servicesListSubtitle1')}`,
            beforeImg: final1,
        },
        {
            title: `${t('servicesListTitle2')}`,
            text: `${t('servicesListSubtitle2')}`,
            beforeImg: final1,
        },
        {
            title: `${t('servicesListTitle3')}`,
            text: `${t('servicesListSubtitle3')}`,
            beforeImg: final1,
        },
        {
            title: `${t('servicesListTitle4')}`,
            text: `${t('servicesListSubtitle4')}`,
            beforeImg: final1,
        },


    ];
    const info2 = [
        {
            title: `${t('servicesListTitle5')}`,
            text: `${t('servicesListSubtitle5')}`,
            beforeImg: final1,
        },
        {
            title: `${t('servicesListTitle6')}`,
            text: `${t('servicesListSubtitle6')}`,
            beforeImg: final1,
        },
        {
            title: `${t('servicesListTitle7')}`,
            text: `${t('servicesListSubtitle7')}`,
            beforeImg: final1,
        }];

    return (
        <div className={styles.wrapper}>
            <h1 className={styles.title}>
                {t('servicesTitle')}
            </h1>
            <div className={styles.content_wrapper}>
                <div className={styles.secondPart}>
                    {info.map((item) => {
                        return <Service title={item.title} text={item.text} beforeImage={item.beforeImg}/>
                    })}
                </div>
                <div className={styles.secondPart}>
                    {info2.map((item) => {
                        return <Service title={item.title} text={item.text} beforeImage={item.beforeImg}/>
                    })}
                </div>
            </div>
            <div className={styles.backgroundImage}>
            </div>
        </div>
    )
}

export default Services