import React, {useEffect} from 'react'
import styles from "./styles.module.scss"
import {getNews} from '../../../Requests/request'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import api from '../../../Requests/axios';
import {Link} from 'react-router-dom';
import {useDispatch} from "react-redux";
import {getProjectsAll} from "../../../redux/reducers/projectsReducer";

const AdminBoard = ({data, url, type}) => {

    const dispatch = useDispatch();

    const a = 'abc';
    console.log(a.length)
    return (
        <div className={styles.wrapper}>
            <TableContainer component={Paper}>
                <Table sx={{width: '100%'}} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">№</TableCell>
                            <TableCell align="left">Язык</TableCell>
                            <TableCell align="center">Заголовок</TableCell>
                            <TableCell align="center">Фотография&nbsp;(jpg)</TableCell>
                            <TableCell align="center">Видео&nbsp;(link)</TableCell>
                            <TableCell align="center">Действие</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data && data.length > 0 ? data.map((row, idx) => (
                                <TableRow key={row.name}>
                                    <TableCell align="left">{idx + 1}</TableCell>
                                    <TableCell
                                        align="left">{row.languageDto.id === 1 ? 'КЫРГ' : row.languageDto.id === 2 ? 'РУС' : row.languageDto.id === 3 ? 'ENG' : ''}</TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        {row.head.length > 16 ? `${row.head.substr(0, 15)}...` : row.head}
                                    </TableCell>
                                    <TableCell
                                        align="center">{row.imageDtos ? (row.imageDtos[0]?.fileName.length > 16 ? `${row.imageDtos[0]?.fileName.substr(0, 15)}...` : row.imageDtos[0]?.fileName) : ''}</TableCell>
                                    <TableCell
                                        align="center">{row.youtubeLink.length > 16 ? `${row.youtubeLink.substr(0, 15)}...` : row.youtubeLink}</TableCell>
                                    <TableCell align="center">
                                        <div className={styles.do}>
                                            <button className={styles.btn} onClick={async (e) => {
                                                await api.delete(`${url}/${+row.id}`);
                                                document.location.reload();
                                            }}>
                                                <i className="ri-delete-bin-7-line"/>
                                            </button>
                                            <Link to={`${row.id}`}>
                                                <i className="ri-edit-line"/>
                                            </Link>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))
                            : ''
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default AdminBoard