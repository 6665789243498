import axios from "axios";

// export const API_URL = "https://tireck-backend.herokuapp.com";
export const API_URL = "https://tireck-backend.herokuapp.com";
const appJWTToken = localStorage.getItem("token")

const api = axios.create({
    baseURL: API_URL,
    withCredentials: false,
    mode: 'no-cors',
    headers: {
       
        Authorization: `Bearer ${appJWTToken}`,

      }
})


// api.interceptors.request.use((config)=>{
//     config.headers.Authorization = `${localStorage.getItem("token")}`
//     return config;
// })
export default api
