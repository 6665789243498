import api from "./axios"
import axios from 'axios';
export const LOGIN_URL = "https://tireck-backend.herokuapp.com/login"
const CREATE_POST_URL = "https://tireck-backend.herokuapp.com/admin/post"
const GET_NEWS_URL = "​https://tireck-backend.herokuapp.com/post​/getAll"
const CREATE_PROJECT_URL = "https://tireck-backend.herokuapp.com/admin/project"
const GET_ALL_PROJECTS = "https://tireck-backend.herokuapp.com/project/getAll"
// const LOGIN_URL = "http://46.101.166.144:8080/login"
// const CREATE_POST_URL = "http://46.101.166.144:8080/admin/post"
// const GET_NEWS_URL = "​http://46.101.166.144:8080/post​/getAll"
// const CREATE_PROJECT_URL = "http://46.101.166.144:8080/admin/project"
// const GET_ALL_PROJECTS = "http://46.101.166.144:8080/project/getAll"



export const createNews = async (file, description, head, videoLink) => {
  const formData = new FormData()
  formData.append('files', file)
  const dto_object = new Blob([JSON.stringify({
    description: description,
    head: head,
    youtubeLink: videoLink,
    
  })], {
    type: 'application/json'
  })
  formData.append("createProjectDto", dto_object, '')

  const response = await axios({
    method: "post",
    url: CREATE_POST_URL,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${localStorage.getItem("token")}`, }
  })
  return response

}
export const createProject = async (file, description, head, videoLink) => {
  const formData = new FormData()
  formData.append('files', file)
  const dto_object = new Blob([JSON.stringify({
    description: description,
    head: head,
    youtubeLink: videoLink,

  })], {
    type: 'application/json'
  })
  formData.append("createProjectDto", dto_object, '')

  const response = await axios({
    method: "post",
    url: CREATE_PROJECT_URL,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${localStorage.getItem("token")}`, }
  })
  return response
}
export const getNews = async () => {
  const response = await axios.get(GET_NEWS_URL)
 
  return response
}
export const getProjects = async () => {
  const req = await axios.get(GET_ALL_PROJECTS)
  const res = await req.json()
  return res
}
