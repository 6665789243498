import React from 'react'
import styles from "./styles.module.scss"
import {NavLink as Link, useLocation} from 'react-router-dom';

const Navbar = () => {
    const links = [{
        value: "Новости",
        path: "/news",
    },
        {
            value: "Проекты",
            path: "/projects",
        },
    ]
    const location = useLocation();
    console.log(typeof +`${location.pathname.slice(-1)}` === 'number', isNaN(+location.pathname.slice(-1)));
    return (
        <div className={styles.wrapper}>
            <ul className={styles.list}>
                {links.map((item) => {
                    if (isNaN(+location.pathname.slice(-1))) {
                        return <Link to={`/admin${item.path}`}
                                     className={({isActive}) => isActive ? styles.active : styles.link}>{item.value}</Link>
                    } else {
                        return <p
                            className={location.pathname.includes(item.path) ? styles.active : styles.link}>{item.value}</p>

                    }
                })}
            </ul>
        </div>
    )
};

export default Navbar