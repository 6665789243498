import React from 'react';
import styles from './newsCard.module.scss';
import {Button} from "@mui/material";
import {useNavigate, Link, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import './newsCard.css';

const NewsCard = ({id, title, subtitle, image, cardDate, width, cardClass}) => {

    const navigate = useNavigate();

    const {t} = useTranslation();
    return (
        <div style={{width: width}} className={styles.box}>
            <div className={styles.boxImg}>
                {
                    Array.isArray(image) ?
                        image.map((item, idx) => (
                            <img key={`${idx}${item.url}`} src={item.url} alt=""/>
                        ))
                        : <img className={styles.img} src={image} alt=""/>
                }
            </div>
            <div className={styles.content}>
                <div>
                    {cardDate !== undefined ?
                        <p className={styles.date}>
                            {new Intl.DateTimeFormat('ru-Ru', {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                            }).format(new Date(cardDate))}
                        </p> : ''}
                    {/*<h3 className={styles.title}>{title.substr(0, 18)}{title.length > 20 ? '...' : ''}</h3>*/}
                    <h3 className={styles.title}>{title.substr(0, 100)}{title.length > 100 ? '...' : ''}</h3>
                    {/*<p className={styles.subtitle}>{subtitle.substr(0, 140)}{subtitle.length > 140 ? '...' : ''}</p>*/}
                </div>
                <div className={styles.btnBox}>
                    <Button variant="text" className={styles.btn}>
                        {t('newsCardButton')}
                        <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M10 0.333374L8.2375 1.86087L15.2125 7.91671H0V10.0834H15.2125L8.2375 16.1392L10 17.6667L20 9.00004L10 0.333374Z"
                                fill="#4A99D3"/>
                        </svg>
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default NewsCard;