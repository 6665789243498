import {Projects} from "../api";
import {loaderOff, loaderOn} from "./loaderReducer";

const initialState = {
    projects: {},
    projectsById: {},
    projectsByCategory: {},
    projectsAll: {}
};

const projects = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ALL_PROJECTS': {
            return {
                ...state,
                projects: action.data,
            };
        }
        case 'GET_PROJECTS_BY_ID': {
            return {
                ...state,
                projectsById: action.data
            };
        }
        case 'GET_PROJECTS_BY_CATEGORY': {
            return {
                ...state,
                projectsByCategory: action.data
            };
        }
        case 'GET_PROJECTS': {
            return {
                ...state,
                projectsAll: action.data
            };
        }
        default:
            return state
    }
};


export const setProjects = (data) => ({type: 'GET_ALL_PROJECTS', data});
export const setProjectsById = (data) => ({type: 'GET_PROJECTS_BY_ID', data});
export const setProjectsAll = (data) => ({type: 'GET_PROJECTS', data});
export const setProjectsByCategory = (data) => ({type: 'GET_PROJECTS_BY_CATEGORY', data});

const body = document.querySelector('body');
export const getProjectsAll = (language, type) => async (dispatch) => {
    // dispatch(loaderOn());
    // body.style.overflow = 'hidden';
    await Projects.getProjects(language, type).then((response) => {
        dispatch(setProjects(response));
        // if (response.status === 200) {
        //     // body.style.overflow = 'auto';
        //     dispatch(loaderOff());
        // }
    });

};

export const getProjects = () => async (dispatch) => {
    await Projects.getAllProjects().then((response) => dispatch(setProjectsAll(response)));
};

export const getProjectsById = (id, language) => async (dispatch) => {
    await Projects.getProjectsById(id, language).then((response) => dispatch(setProjectsById(response)));
};

export const getProjectsByCategory = (category) => async (dispatch) => {
    await Projects.getProjects().then((response) => {
        const filtered = response.data.filter(item => item.typeDto?.type === category);
        dispatch(setProjectsByCategory(filtered))
    })
};

export default projects;