import React, {useEffect, useState} from 'react'
import {NavLink as Link, Route, Routes, useLocation} from 'react-router-dom';
import CreateProject from '../../Сomponents/organisms/CreateProject/CreateProject';
import CreateNews from '../../Сomponents/organisms/CreateNews/CreateNews';
import styles from "./styles.module.scss"
import Navbar from "../../Сomponents/atoms/AdminNavbar/Navbar"
import {useDispatch} from 'react-redux';
import store from '../../Store/store';
import {getNews} from "../../redux/reducers/newsReducer";
import {useSelector} from 'react-redux';
import News from "./Pages/NewsPages/News";
import Project from './Pages/ProjectsPages/Project';
import {useNavigate} from 'react-router-dom';
import EditNews from "./Pages/EditNews";
import EditProjects from "./Pages/EditProjects";
import MyModal from "../../Сomponents/atoms/Modal/Modal";

const AdminApp = () => {

    const dispatch = useDispatch();
    const state = store.getState();
    const navigate = useNavigate();
    useEffect(() => {
        dispatch(getNews())
    }, [document.location.pathname]);

    const {newsAll} = useSelector(({news}) => news);
    const [data, setData] = useState(null);
    useEffect(() => {
        if (newsAll) {
            setData(newsAll.data)
        }
    }, [newsAll]);
    const [open, setOpen] = useState(false);
    const [modalText, setModalText] = useState("");
    const location = useLocation();
    return (
        <div>
            <header className={styles.header}>
                <h3 className={styles.user}>admin</h3>
                <button onClick={(e) => {
                    if (isNaN(+location.pathname.slice(-1))) {
                        navigate("/");
                        return localStorage.removeItem("token")
                    }
                }}>выйти
                </button>
            </header>
            {/*<MyModal open={open} text={modalText} title={"статус создания новости"} handleClose={setOpen}/>*/}
            <div className={styles.content_wrapper}>
                <Navbar/>
                <div className={styles.container}>
                    <Routes>
                        <Route path="/news" element={<News data={data}/>}/>
                        <Route path="/projects" element={<Project/>}/>
                        <Route path="/createProject"
                               element={<CreateProject setOpen={setOpen} setModalText={setModalText}/>}/>
                        <Route path="/createNews"
                               element={<CreateNews setOpen={setOpen} setModalText={setModalText}/>}/>
                        <Route path="/news/:id" element={<EditNews setOpen={setOpen} setModalText={setModalText}/>}/>
                        <Route path="/projects/:id"
                               element={<EditProjects setOpen={setOpen} setModal={setModalText}/>}/>
                    </Routes>
                </div>
            </div>

        </div>
    )
}

export default AdminApp