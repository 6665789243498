import React, {useEffect, useState} from 'react';
import NewsCard from "../NewsCard";
import styles from './recomend.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {getNewsAll} from "../../../redux/reducers/newsReducer";
import {useNavigate, Link} from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";

const RecomendNews = ({id}) => {

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getNewsAll(localStorage.language ? localStorage.language : 1))
    }, [localStorage.language]);
    const {news} = useSelector(({news}) => news);
    const [data, setData] = useState(null);
    useEffect(() => {
        if (news && news.data) {
            setData(news.data.filter(el => el.id !== id))
        }
    }, [news, id]);

    return (
        <div className={styles.column}>
            {
                data && data.length > 0 ?
                    data.slice(0, 3).map((item, idx) => (
                        <React.Fragment key={`${item.data}${idx}`}>
                            <Link to={`/news/${item.id}`}>
                                <NewsCard id={item.id} subtitle={item.description} title={item.head}
                                          image={item.imageDtos}
                                          cardDate={item.date}/>
                            </Link>
                        </React.Fragment>
                    ))
                    : <Skeleton variant="rectangular" width={"100%"} height={200}/>
            }
        </div>
    );
};

export default RecomendNews;