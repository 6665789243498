import {News} from "../api";

const initialState = {
    news: {},
    newsById: {},
    newsAll: {}
};

const news = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ALL_NEWS': {
            return {
                ...state,
                news: action.data,
            };
        }
        case "GET_NEWS": {
            return {
                ...state,
                newsAll: action.data
            }
        }
        case 'GET_NEWS_BY_ID': {
            return {
                ...state,
                newsById: action.data
            };
        }
        default:
            return state
    }
};

export const setNews = (data) => ({type: 'GET_ALL_NEWS', data});
export const setNewsAll = (data) => ({type: 'GET_NEWS', data});
export const setNewsById = (data) => ({type: 'GET_NEWS_BY_ID', data});

export const getNewsAll = (language) => async (dispatch) => {
    await News.getNews(language).then((response) => dispatch(setNews(response)))
};
export const getNews = () => async (dispatch) => {
    await News.getAllNews().then((response) => dispatch(setNewsAll(response)))
};
export const getNewsById = (id, language) => async (dispatch) => {
    await News.getNewsById(id, language).then((response) => dispatch(setNewsById(response)))
};

export default news;