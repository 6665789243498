import React from 'react'
import {AboutUsLogo} from '../../../Assets/icons'
import {Avatar} from "../../../Assets/images"
import styles from "./styles.module.scss"
import {useTranslation} from "react-i18next";


const AboutCreator = () => {
    const {t} = useTranslation();
    return (
        <div className={styles.wrapper}>
            <div className={styles.firstBlog}>
                <img src={AboutUsLogo} alt="AboutUsLogo"/>
            </div>
            <div className={styles.secondBlog}>
                <p className={styles.text}>
                    {t('aboutUsCreator')}
                </p>
                <div className={styles.image}>
                    <img src={Avatar} alt="Avatar"/>
                </div>
            </div>
            <h1 className={styles.author}>
                {t('aboutUsCreatorName')}
            </h1>
        </div>
    )
};

export default AboutCreator