import React, {useState} from 'react'
import styles from "./styles.module.scss"
import {TextField} from "@mui/material";
import axios from "axios";
import {useTranslation} from "react-i18next";

const ContactPage = () => {

    const {t} = useTranslation();

    const [name, setName] = useState("");
    const [phone, setPhone] = useState(null);
    const [email, setMail] = useState(null);
    const [message, setMessage] = useState(null);
    const [files, setFiles] = useState(null);

    const postAppealUrl = 'https://tireck-backend.herokuapp.com/appeal';

    const createNews = async (file, name, phone, email, message) => {
        const formData = new FormData();
        formData.append('files', file);
        const dto_object = new Blob([JSON.stringify({
            fullName: name,
            telephoneNumber: +phone,
            email: email,
            message: message
        })], {
            type: 'application/json'
        });
        formData.append("submissionFormDto", dto_object, '');

        const response = await axios({
            method: "post",
            url: postAppealUrl,
            data: formData,
            headers: {'Content-Type': 'multipart/form-data'}
        });
        return response
    };

    console.log(files)

    return (
        <div className={styles.wrapper}>
            <div className="wrapper">
                <div className={styles.appeal}>
                    <div className={styles.appealPageOne}>
                        <h1 className={styles.appealWord}>{t('contactPageTitle')}</h1>
                        <div className={styles.appealContent}>
                            <div className={styles.urmat}>{t('contactPageSubtitle')}</div>
                            <div className={styles.p}>{t('contactPageDescription1')}</div>
                            <div className={styles.pTwo}>{t('contactPageDescription2')}</div>
                        </div>
                    </div>
                    <div className={styles.appealPageTwo}>
                        <div className={styles.appealContentTwo}>
                            <div className={styles.appealTextTwo}>{t('contactPageContent')}</div>
                            <div className={styles.divUl}>
                                <ul>
                                    <li className={styles.liTwo}><i
                                        className="ri-arrow-right-s-fill"/>{t('contactPageContentItem1')}</li>
                                    <li className={styles.liTwo}><i
                                        className="ri-arrow-right-s-fill"/>{t('contactPageContentItem2')}</li>
                                    <li className={styles.liTwo}><i
                                        className="ri-arrow-right-s-fill"/>{t('contactPageContentItem3')}</li>
                                    <li className={styles.liTwo}><i
                                        className="ri-arrow-right-s-fill"/>{t('contactPageContentItem4')}</li>
                                </ul>
                            </div>

                        </div>
                    </div>
                    <div className={styles.appealPageThree}>
                        <div className={styles.appealContentThree}>
                            <div className={styles.TextThree}>
                                <span className={styles.th}>{t('contactPageAttention')}</span>
                                {t('contactPageAttentionTitle')}
                            </div>
                            <div className={styles.divUlThree}>
                                <ul>
                                    <li className={styles.liThree}><i
                                        className="ri-error-warning-line"/>{t('contactPageAttentionItem1')}</li>
                                    <li className={styles.liThree}><i
                                        className="ri-error-warning-line"/>{t('contactPageAttentionItem2')}</li>
                                    <li className={styles.liThree}><i
                                        className="ri-error-warning-line"/>{t('contactPageAttentionItem3')}</li>
                                    <li className={styles.liThree}><i
                                        className="ri-error-warning-line"/>{t('contactPageAttentionItem4')}</li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
                <div className={styles.inputs}>
                    <div className={styles.inputsBorder}>
                        <div className={styles.inputsPageOne}>
                            <form onSubmit={(e) => {
                                e.preventDefault();
                                createNews(
                                    files,
                                    name,
                                    phone,
                                    email,
                                    message,
                                ).then(response => {
                                    if (response.status === 200) {
                                    }
                                })
                            }}>
                                <div className={styles.formRow}>
                                    <div className={styles.formDiv}>
                                        <TextField required={true} onChange={(e) => {
                                            setName(e.target.value)
                                        }} className={styles.inputPhones}
                                                   label={t('inputName')} variant="outlined"/>
                                        <TextField required={true} type="number" onChange={(e) => {
                                            setPhone(e.target.value)
                                        }} className={styles.inputPhones}
                                                   label={t('inputPhone')} variant="outlined"/>
                                        <TextField type="email" onChange={(e) => {
                                            setMail(e.target.value)
                                        }} className={styles.inputPhones}
                                                   label={t('inputEmail')} variant="outlined"/>
                                    </div>
                                    <div className={styles.formDiv}>
                                        <TextField required={true}
                                            onChange={(e) => {
                                                setMessage(e.target.value)
                                            }}
                                            multiline
                                            rows={5} label={t('inputMessage')}
                                            variant="outlined"
                                            className={styles.inputsComment}/>
                                        <div className={styles.BtnDownload}>
                                            <i className="ri-download-2-fill"/>
                                            <div className={styles.wordDown}>{t('inputFileTitle')}</div>
                                            <label className={styles.filePlaceholder}>
                                                <button onClick={() => document.getElementById('inputFile').click()}
                                                        type="button">
                                                    {t('inputFileButton')}
                                                </button>
                                                <input style={{display: "none"}} id="inputFile" className={styles.file}
                                                       multiple
                                                       type="file" onChange={(e) => {
                                                    setFiles(e.target.files[0])
                                                }}/>
                                                {files ?
                                                    files.name
                                                    : t('inputFileStatus')
                                                }
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.btnDiv}>
                                    <button type="submit" className={styles.Button}>{t('buttonSend')}</button>
                                </div>
                            </form>
                        </div>

                    </div>
                    <div className={styles.Email}>
                        {t('contactPageLastDescription')}
                        <span className={styles.emaile}>tirek.inbox@gmail.com.</span>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ContactPage