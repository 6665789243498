import '../App.css';
import {Header, Footer} from '../Сomponents/moleculas';
import {Routes, Route, useLocation,} from "react-router-dom"
import {MainPage, Login, ContactPage, Gallery, PageNotFound, NewsPage, Projects} from '../Pages/index';
import "@fancyapps/ui/dist/fancybox.css";
import React, {useEffect, useState} from "react";
import 'remixicon/fonts/remixicon.css'
import Item from "../Pages/NewsItem";
import {Helmet} from "react-helmet";
import store from "../Store/store"
import {useDispatch} from 'react-redux';
import {getAllNews} from "../Store/Thunks/newsThunk"
import ProjectItem from "../Pages/ProjectItem";
import NewsItem from "../Pages/NewsItem";
import PrivateRoute from "../PrivateRoute/PrivateRoute";
import AdminApp from "./AdminApp/AdminApp";
import favicon from '../Assets/icons/favicon.png';

function UserApp() {
    const location = useLocation();

    useEffect(() => {
        document.documentElement.style.scrollBehavior = "unset";
        window.scrollTo(0, 0);
        document.documentElement.style.scrollBehavior = "smooth";

    }, [location.pathname]);

    const token = localStorage.getItem("token");
    const [auth, setAuth] = useState(true);
    useEffect(() => {
        if (token == null) {
            setAuth(false)
        }
    }, []);

    return (
        <div className="App">
            <div>
                <Helmet>
                    <title>Эне-балага тирек</title>
                    <link rel="icon"
                          href={favicon}/>
                </Helmet>
                <Header/>
                <div className="content_wrapper">
                    <Routes>
                        <Route path={"/"} element={<MainPage/>}/>
                        <Route path={"/appeal"} element={<ContactPage/>}/>
                        <Route path={"/gallery"} element={<Gallery page={true}/>}/>
                        <Route path={"/news"} element={<NewsPage/>}/>
                        <Route path={"/admin"} element={
                            // <Admin/>
                            <PrivateRoute auth={auth}>
                                <AdminApp/>
                            </PrivateRoute>
                        }/>
                        <Route path='' component={PageNotFound}/>
                        <Route path="/projects/:category" element={<Projects/>}/>
                        <Route path="/projects/:category/:id" element={<ProjectItem/>}/>
                        <Route path="/news/:id" element={<NewsItem/>}/>
                        <Route path={"/login"} element={<Login/>}/>
                    </Routes>


                </div>
                <Footer/>
            </div>


        </div>
    );
}

export default UserApp;
