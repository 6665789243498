import React, {useState, useEffect} from "react";
import styles from "./styles.module.scss";
import logo from '../../../Assets/images/logo.png';
import enLogo from "../../../Assets/icons/ENEbalagatirekENGLogo.png";
import {Navigation} from "../../atoms";
import {MaxMedium, MinMedium} from "../../../utils/mediaQuiries";
import {Link, useNavigate} from "react-router-dom";
import KGlang from '../../../Assets/images/languages/kg.png';
import ENlang from '../../../Assets/images/languages/en.png';
import RUlang from '../../../Assets/images/languages/ru.png';
import i18n from "../../../i18n";
import {useTranslation} from "react-i18next";
import {HashLink} from 'react-router-hash-link';

const Header = ({isAdmin}) => {
    const [color, setColor] = useState("");
    const [background, setBackground] = useState("");

    const {t} = useTranslation();

    const links = [
        {
            text: `${t('headerMenuHome')}`,
            path: "/",
        },
        {
            text: `${t('headerMenuNews')}`,
            path: "/news",
        },
        {
            text: `${t('headerMenuProjects')}`,
            path: "/projects/education",
        },
        {
            text: `${t('headerMenuAppeals')}`,
            path: "/appeal",
        },
        {
            text: `${t('headerMenuGallery')}`,
            path: "/gallery",
        },

    ];

    const listenScrollEvent = () => {
        if (window.scrollY > 50) {
            setColor(" 1px solid ")

        } else {
            setColor("none")

        }
    };
    const [languageStatus, setLanguageStatus] = useState(false);
    useEffect(() => {
        setLanguageStatus(true);
        window.addEventListener("scroll", listenScrollEvent);
        i18n.changeLanguage(localStorage.language ? localStorage.language : '1');
        return () => {
            window.removeEventListener("scroll", listenScrollEvent);
        };
    }, [localStorage.language, languageStatus]);

    const [menu, setMenu] = useState(false);
    const menuHandler = () => {
        setOpenLang(false);
        if (document.querySelector('body').style.overflow === 'auto') {
            document.querySelector('body').style.overflow = 'hidden';
        } else if (document.querySelector('body').style.overflow === 'hidden') {
            document.querySelector('body').style.overflow = 'auto';
        }
        setMenu(!menu)
    };

    const navigate = useNavigate();

    const scrollToContact = () => {
        // function f() {
        //     return Promise.resolve(navigate('/'));
        // }
        //
        // f().then(() => {
        //     window.scrollTo({
        //         top: document.getElementById('contactsSection').offsetTop,
        //         behavior: "smooth"
        //     })
        // })
        navigate('/');
        setTimeout(() => {
            window.scrollTo({
                top: document.getElementById('contactsSection').offsetTop,
                behavior: "smooth"
            })
        }, 50)
    };
    const [openLang, setOpenLang] = useState(false);

    return (
        <div className={styles.component_wrapper} style={{borderBottom: color}}>
            <div className={styles.headerContainer}>
                <div className={styles.pageWrapper}>
                    <div className={styles.logoBlock}>
                        <Link to="/">
                            <img src={localStorage.language === '3' ? enLogo : logo} alt="Logo"/>
                        </Link>
                    </div>
                    <MinMedium>
                        <div className={styles.navigation}>
                            <Navigation links={links}/>
                        </div>
                        <div className={styles.btnsBox}>
                            <div onClick={() => setOpenLang(!openLang)} className={styles.switch}>
                                <div className={openLang ? styles.switchBoxOpen : styles.switchBox}>
                                    <div style={{order: `${localStorage.language === '1' ? '-1' : '1'}`}}
                                         onClick={() => {
                                             if (openLang) {
                                                 localStorage.setItem('language', '1');
                                             }
                                             setOpenLang(!openLang);
                                         }}>
                                        <h2>КЫР</h2>
                                        {/*<img src={KGlang} alt=""/>*/}
                                    </div>
                                    <div style={{order: `${localStorage.language === '2' ? '-1' : '1'}`}}
                                         onClick={() => {
                                             if (openLang) {
                                                 localStorage.setItem('language', '2');
                                             }
                                             setOpenLang(!openLang);
                                         }}>
                                        <h2>РУС</h2>
                                        {/*<img src={RUlang} alt=""/>*/}
                                    </div>
                                    <div style={{order: `${localStorage.language === '3' ? '-1' : '1'}`}}
                                         onClick={() => {
                                             if (openLang) {
                                                 localStorage.setItem('language', '3');
                                             }
                                             setOpenLang(!openLang);
                                         }}>
                                        <h2>ENG</h2>
                                        {/*<img src={ENlang} alt=""/>*/}
                                    </div>
                                </div>
                                <i style={{transform: `${openLang ? 'rotate(-180deg) translate(-50%, 0)' : 'rotate(0) translate(50%, 0)'}`}}
                                   className="ri-arrow-down-s-line"/>
                            </div>
                            <button onClick={() => scrollToContact()}
                                    className={styles.button}>{t('headerButton')}</button>
                        </div>
                    </MinMedium>
                    <MaxMedium>
                        <div>
                            <div className={styles.div}>
                                <div onClick={() => setOpenLang(!openLang)} className={styles.switch}>
                                    <div className={openLang ? styles.switchBoxOpen : styles.switchBox}>
                                        <div style={{order: `${localStorage.language === '1' ? '-1' : '1'}`}}
                                             onClick={() => {
                                                 if (openLang) {
                                                     localStorage.setItem('language', '1');
                                                 }
                                                 setOpenLang(!openLang);
                                             }}>
                                            <h2>КЫР</h2>
                                            {/*<img src={KGlang} alt=""/>*/}
                                        </div>
                                        <div style={{order: `${localStorage.language === '2' ? '-1' : '1'}`}}
                                             onClick={() => {
                                                 if (openLang) {
                                                     localStorage.setItem('language', '2');
                                                 }
                                                 setOpenLang(!openLang);
                                             }}>
                                            <h2>РУС</h2>
                                            {/*<img src={RUlang} alt=""/>*/}
                                        </div>
                                        <div style={{order: `${localStorage.language === '3' ? '-1' : '1'}`}}
                                             onClick={() => {
                                                 if (openLang) {
                                                     localStorage.setItem('language', '3');
                                                 }
                                                 setOpenLang(!openLang);
                                             }}>
                                            <h2>ENG</h2>
                                            {/*<img src={ENlang} alt=""/>*/}
                                        </div>
                                    </div>
                                    <i style={{transform: `${openLang ? 'rotate(-180deg) translate(-50%, 0)' : 'rotate(0) translate(50%, 0)'}`}}
                                       className="ri-arrow-down-s-line"/>
                                </div>
                                <div className={styles.burger}>
                                    {
                                        menu ? <i onClick={() => menuHandler()} className="ri-close-line"/> :
                                            <i onClick={() => menuHandler()} className="ri-menu-line"/>
                                    }
                                </div>
                            </div>
                            <div className={menu ? styles.popupOpen : styles.popup}>
                                <div className={styles.popupContent}>
                                    <ul>
                                        <li>
                                            <Link onClick={() => menuHandler()} to="/">
                                                {t('headerMenuHome')}
                                            </Link>
                                        </li>
                                        <li>
                                            <Link onClick={() => menuHandler()} to="/news">
                                                {t('headerMenuNews')}
                                            </Link>
                                        </li>
                                        <li>
                                            <Link onClick={() => menuHandler()} to="/projects/education">
                                                {t('headerMenuProjects')}
                                            </Link>
                                        </li>
                                        <li>
                                            <Link onClick={() => menuHandler()} to="/appeal">
                                                {t('headerMenuAppeals')}
                                            </Link>
                                        </li>
                                        <li>
                                            <Link onClick={() => menuHandler()} to="/gallery">
                                                {t('headerMenuGallery')}
                                            </Link>
                                        </li>
                                        {/*<li>*/}
                                        {/*    <Link onClick={() => scrollToContact()} to="/">*/}
                                        {/*        {t('headerButton')}*/}
                                        {/*    </Link>*/}
                                        {/*</li>*/}
                                    </ul>
                                    <div className={styles.email}>
                                        <i className="ri-mail-line"/>
                                        <a href="mailto:tirek.org@gmail.com ">enebalagatirek@gmail.com
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </MaxMedium>
                </div>
            </div>
        </div>

    );
};

export default Header;